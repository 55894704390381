.terms-of-references {
  padding: 180px 0 100px;
}
.terms-of-references .al-logo {
  right: 0;
  top: -100px;
}
@media (max-width: 1600px) {
  .terms-of-references {
    padding: 140px 0 90px;
  }
}
@media (max-width: 991px) {
  .terms-of-references {
    padding: 100px 0 70px;
  }
  .terms-of-references .al-logo {
    right: 10px;
    top: -80px;
  }
}
@media (max-width: 575px) {
  .terms-of-references .reference-img {
    width: calc(100% - 40px);
  }
}
