.electronic-library {
    padding: 30px 0;
}
.electronic-library .electronic-btn{
    justify-content: end;
    text-align: right;
    display: flex;
    margin-left: auto;
}
.filter-bar {
    background: light-dark(#FFFFFF, #242424);
    box-shadow: 0px 0px 16px #0000001A;
    border-radius: 8px;
    margin-top: 40px;
    padding: 30px;
}
.filter-bar .form-control{
  padding: 10px;
  font-size: 18px;
  line-height: 1.3;
}
.filter-bar .btn-solid-blue{
  font-weight: 400;
  flex-grow: 1;
}
  /* .custom-select {
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: var(--normal-font-size);
    color: #343434;
    background-color: #fff;
    outline: none;
    box-shadow: none;
    border-left: navajowhite;
    border-right: none;
    border-top: none;
    cursor: pointer;
} */
/* .custom-select:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
}
  
  .search-button {
    background-color: #004080;
    border: none;
    font-weight: bold;
  }
  
  .search-button:hover {
    background-color: #003366;
  } */

@media (max-width: 1399px) {
  .filter-bar .form-control{
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .filter-bar .btn-solid-blue{
    flex-grow: 0;
  }
}
@media (max-width: 768px){
.electronic-btn{
  margin-top: 10px;
  }
}
@media (max-width: 575px) {
  .filter-bar{
    padding: 30px 20px;
  }
  .filter-bar .form-control{
    padding: 8px 10px;
    font-size: 14px;
  }
}
