.training-courses {
  padding: 0 0 100px;
}
.info-card .card-body {
  color: var(--text-secondary-color);
  padding: 26px 24px;
  box-shadow: 0px 0px 20px #0000000f;
}
.info-card .card-body .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.info-card .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 991px) {
  .info-card .card-body {
    padding: 15px;
  }
  .training-courses {
    padding: 0 0 60px;
  }
}