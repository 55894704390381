.latest-news-sec {
  padding: 100px 0;
}
@media (max-width: 1399px) {
  .latest-news-sec {
    padding: 80px 0;
  }
}
@media (max-width: 575px) {
  .latest-news-sec {
    padding: 60px 0;
  }
}
