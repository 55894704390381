.event-card-wrapper {
  border-bottom: 1px solid light-dark(#d5d5d5, #242424); padding-bottom: 15px;
}
.event-card-wrapper:not(:last-child) { margin-bottom: 15px; }
.event-card-wrapper .date-box {
  width: 112px; border-radius: 4px; background-color: var(--theme-secondary);
}
.event-card-wrapper .content-box { flex: 1; }
.event-card-wrapper .location-wrapper :is(svg, span) {
  font-size: var(--font-size-small); line-height: 1.3; color: var(--theme-secondary); 
}
.event-card-wrapper .location-wrapper span, .event-card-wrapper .content-box h6 {
  display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;
}
.event-card-wrapper .content-box p {
  display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; margin-bottom: 8px;
}

@media (max-width: 1699px) {
  .event-card-wrapper { padding-bottom: 13px; }
  .event-card-wrapper:not(:last-child) { margin-bottom: 13px; }
}
@media (max-width: 1399px) {
  .event-card-wrapper .content-box h6 { font-size: 14px; }
  .event-card-wrapper .date-box { width: 95px; }
}
@media (max-width: 575px) {
  .event-card-wrapper .date-box { width: 80px; }
  .event-card-wrapper .location-wrapper span { font-size: 13px; }
}
