.stats-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-image: url("../../assets/images/statestics.webp");
    background-size: cover;
}
.stats-section .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stats-section .stat-value {
    font-size: 28px;
    line-height: 1.3;
}
@media (max-width: 767px) {
    .stats-section {
        padding: 40px 0;
    }
    .stats-section .stat-value {
        font-size: 24px;
    }
}