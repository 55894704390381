.photo-album {
  padding: 60px 0 30px;
}
.photo-album .content-wrapper {
  border-radius: 8px;
  overflow: hidden;
}
.photo-album .img-warpper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(180deg, #00000000 0%, #0000002b 48%, #000000ea 100%) 0% 0%
    no-repeat padding-box;
}
.photo-album .details {
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all ease-in-out 0.3s;
}
.photo-album .content-wrapper:hover .hover-content {
  opacity: 1;
}
.photo-album .content-wrapper:hover .details {
  transform: translateY(-20px);
  opacity: 0;
}
.photo-album .hover-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg,#00000000 0%,#0000002b 0%,#000000ea 100%);
  opacity: 0;
  transition: all ease-in-out 0.4s;
}
.photo-album .hover-content .img-icon1 {
  transform: rotate(-20deg);
}
.photo-album .hover-content .img-icon2 {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  transform: rotate(10deg);
}
@media (max-width: 991px) {
  .photo-album {
    padding: 40px 0 30px;
  }
}
@media (max-width: 575px) {
  .photo-album .details .date {
    font-size: 14px;
  }
  .photo-album .details h4 {
    font-size: var(--font-size-regular);
  }
}
