.news-article-wrapper {
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  color: var(--text-secondary-color);
}
.news-article-img {
  width: 35%;
  height: 100%;
}
.news-article-img img {
  object-fit: cover;
}
.news-article-content {
  padding: 24px 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.news-article-content .upper-sec{
  flex: 1;
}
.news-article-content .pre-heading{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-article-content h4 {
  color: var(--text-secondary-color);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 63px;
}
.news-article-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}
/* .news-article-wrapper:hover h4 {
  color: var(--theme-primary);
} */
.news-article-wrapper:hover .details-arrow {
  color: var(--info-color);
}
@media (max-width: 1399px) {
  .news-article-content {
    padding: 18px 20px;
  }
  .news-article-content h4 {
    height: 58px;
  }
}
@media (max-width: 991px) {
  .news-article-content h4 {
    height: 52px;
  }
}
@media (max-width: 575px) {
  .news-article-content {
    padding: 15px;
  }
  .news-article-content h4 {
    height: 46px;
  }
  .news-article-content p {
    font-size: 13px;
    line-height: 17px;
  }
}
