@font-face{font-family:'Poppins';src:url(./assets/fonts/Poppins-Light.woff2) format("woff2"),url(./assets/fonts/Poppins-Light.woff) format("woff");font-weight:300;font-style:normal;font-display:swap}
@font-face{font-family:'Poppins';src:url(./assets/fonts/Poppins-Medium.woff2) format("woff2"),url(./assets/fonts/Poppins-Medium.woff) format("woff");font-weight:500;font-style:normal;font-display:swap}
@font-face{font-family:'Poppins';src:url(./assets/fonts/Poppins-Regular.woff2) format("woff2"),url(./assets/fonts/Poppins-Regular.woff) format("woff");font-weight:400;font-style:normal;font-display:swap}
@font-face{font-family:'Poppins';src:url(./assets/fonts/Poppins-SemiBold.woff2) format("woff2"),url(./assets/fonts/Poppins-SemiBold.woff) format("woff");font-weight:600;font-style:normal;font-display:swap}
@font-face{font-family:'Poppins';src:url(./assets/fonts/Poppins-Bold.woff2) format("woff2"),url(./assets/fonts/Poppins-Bold.woff) format("woff");font-weight:700;font-style:normal;font-display:swap}
*{padding:0;margin:0;}
:root{
  /* These variables will be same on both light and dark modes */
  --theme-secondary: #003e7f; /* Mostly used in box backgrounds, buttons, radio buttons, check boxes, etc  */
  --text-secondary-color: #343434;
  --info-color: #8F511F;
  --danger-color: #FF0000;
  --font-size-small: 14px;
  --font-size-regular: 16px;
  --font-size-large: 22px;
  --font-size-h1: 60px;
  --font-size-h2: 40px;
  --font-size-h3: 32px;
  --font-size-h4: 24px;
  --font-size-h5: 19px;
  --font-size-h6: 16px;
}
.light-mode{
  color-scheme: light;
  --body-primary-bg: #FFFFFF; /* Body backround */
  --body-secondary-bg: #F5F5F5; /* Sections backround */
  --theme-primary: #002955; /* Mostly used for changeable backrounds and headings */
  --text-primary-color: #171717; /* Mostly used for text that is change in dark mode */
}
.dark-mode{
  color-scheme: dark;
  --body-primary-bg: #000000;
  --body-secondary-bg: #242424;
  --theme-primary: #FFFFFF;
  --text-primary-color: #FFFFFF;
}
.green-theme {--theme-primary: #489510;--theme-secondary: #489510;}
.golden-theme {--theme-primary: #E2B400;--theme-secondary: #E2B400;}
.colorless-mode .app-wrapper, .colorless-mode > div{filter: grayscale(100%);}/* Colorless Mode (Grayscale) */
body{font-family: 'Poppins', sans-serif;font-weight :400;color: var(--text-primary-color);font-size: var(--font-size-regular);line-height: 1.3;background-color: var(--body-primary-bg);overflow-x: hidden;}
body.font-size-xs {--font-size-small: 12px; --font-size-regular: 14px; --font-size-large: 20px; --font-size-h1: 56px; --font-size-h2: 36px; --font-size-h3: 28px; --font-size-h4: 22px; --font-size-h5: 17px; --font-size-h6: 14px;}
body.font-size-sm {--font-size-small: 13px; --font-size-regular: 15px; --font-size-large: 21px; --font-size-h1: 58px; --font-size-h2: 38px; --font-size-h3: 30px; --font-size-h4: 23px; --font-size-h5: 18px; --font-size-h6: 15px;}
body.font-size-md {--font-size-small: 14px; --font-size-regular: 16px; --font-size-large: 22px; --font-size-h1: 60px; --font-size-h2: 40px; --font-size-h3: 32px; --font-size-h4: 24px; --font-size-h5: 19px; --font-size-h6: 16px;}
body.font-size-lg {--font-size-small: 15px; --font-size-regular: 17px; --font-size-large: 23px; --font-size-h1: 62px; --font-size-h2: 42px; --font-size-h3: 34px; --font-size-h4: 25px; --font-size-h5: 20px; --font-size-h6: 17px;}
body.font-size-xl {--font-size-small: 16px; --font-size-regular: 18px; --font-size-large: 24px; --font-size-h1: 64px; --font-size-h2: 44px; --font-size-h3: 36px; --font-size-h4: 26px; --font-size-h5: 21px; --font-size-h6: 18px;}
::-webkit-scrollbar{width: 12px}
::-webkit-scrollbar-track{box-shadow: inset 0 0 5px #9f9f9f;border-radius: 10px}
::-webkit-scrollbar-thumb{background: var(--theme-primary);border-radius: 10px}
/* ::-webkit-scrollbar-thumb:hover{background: #1b3f94} */
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{font-weight: 600;line-height:  1.3;}
h1, .h1{font-size: var(--font-size-h1);font-weight: 300;}
h2, .h2{font-size: var(--font-size-h2);color: var(--theme-primary)}
h3, .h3{font-size: var(--font-size-h3)}
h4, .h4{font-size: var(--font-size-h4)}
h5, .h5{font-size: var(--font-size-h5)}
h6, .h6{font-size: var(--font-size-h6);line-height: 24px;}
p{font-size: var(--font-size-regular);line-height: 26px;}
.p-lg{font-size: var(--font-size-large);line-height: 1.4;}
.p-sm{font-size: var(--font-size-small);line-height: 1.3;}
.pre-heading{color: var(--info-color)}
.primary-color{color: var(--theme-primary);}
.secondary-color{color: var(--theme-secondary);}
.btn-solid-blue{font-size: var(--font-size-regular);line-height:  1.3;font-weight: 600;color: #fff;background-color: var(--theme-secondary);padding: 18px 30px;border: 1px solid var(--theme-secondary);border-radius: 6px;text-transform: uppercase;transition: all ease-in-out .3s;}
.btn-solid-blue:hover{color: var(--theme-secondary);background-color: #fff;border: 1px solid var(--theme-secondary);}
.btn-outline-blue{font-size: var(--font-size-regular);line-height:  1.3;font-weight: 600;color: var(--theme-primary);padding: 18px 20px;border: 1px solid var(--theme-secondary);background-color: transparent;transition: all ease-in-out .3s;}
.btn-outline-blue:hover{color: #fff;border-color: var(--theme-secondary);background-color: var(--theme-secondary);}
.modal{height: 100vh;}
.modal-header, .modal-body{padding-inline: 0;}
.modal-header .btn-close{background-image: url("../src/assets/images/icon-cross.svg");opacity: 1;}
.green-theme .modal-header .btn-close{filter: invert(45%) sepia(68%) saturate(618%) hue-rotate(51deg) brightness(92%) contrast(88%);}
.golden-theme .modal-header .btn-close{filter: brightness(0) saturate(100%) invert(70%) sepia(98%) saturate(1925%) hue-rotate(8deg) brightness(96%) contrast(105%);}
.modal-content{padding-inline: 24px;background: var(--body-primary-bg);}
.btn-sm{padding: 13px 15px}
.text-blue-1{color: var(--theme-primary)}
.text-blue-2{color: var(--theme-secondary);}
.text-yellow{color: var(--info-color)}
.general-list, .ck-content-wrapper ul{padding-left: 25px;list-style: none;}
.rtl .general-list, .rtl .ck-content-wrapper ul{padding: 0 25px 0 0;}
.general-list li, .ck-content-wrapper ul li{font-weight: 600;margin-bottom:15px;position: relative}
.general-list li::before, .ck-content-wrapper ul li::before{content:'';position: absolute;left: -25px;top: 50%;transform:translateY(-50%);width: 11px;height: 11px;border-radius: 10px;background-color: var(--theme-primary)}
.rtl .general-list li::before, .rtl .ck-content-wrapper ul li::before{left: auto;right: -25px;}
.radio-container {display: block;position: relative;padding-left: 35px;margin-bottom: 12px;cursor: pointer;font-size: var(--font-size-large);-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.radio-container input {position: absolute;opacity: 0;cursor: pointer;}
.radio-container .checkmark {position: absolute;top: 0;left: 0;height: 25px;width: 25px;background-color: #fff;border-radius: 50%;}
.radio-container:hover input ~ .checkmark {background-color: #fff;}
.radio-container input:checked ~ .checkmark {background-color: var(--theme-secondary);}
.radio-container .checkmark:after {content: "";position: absolute;display: none;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 8px;height: 8px;border-radius: 50%;background: white;}
.radio-container input:checked ~ .checkmark:after {display: block;}
.dropdown-toggle::after{font-family:"FontAwesome";content:"\f078";border: 0;margin-left: 10px;font-size: 12px}
.details-arrow{color: var(--theme-secondary);font-weight: 600;display: inline-flex}
.details-arrow:hover{color: var(--info-color);}
.social-icons li a{width: 45px;height: 45px;border-radius: 50%;cursor: pointer;transition: all ease-in-out .3s}
.social-icons li a:hover{transform:scale(1.1)}
.social-icons li a svg{font-size: 20px}
.sec-al-logo{position: absolute;z-index: -1;left: -150px;top: -80px;max-width: 330px;}
.slick-track{display:flex}
.slick-list{overflow: hidden;margin: 0 -15px}
.slick-slide{margin: 0 15px}
.slick-prev, .slick-next{z-index: 1;}
.rtl .slick-slide{direction: rtl;text-align: right;}
.rtl .fa-arrow-right-long{transform: rotateY(180deg);}
.sec-right-logo{position: absolute;z-index: -1;right:0;top: 0;
  /* background-color: var(--body-primary-bg); */
  width: 240px;height: 100%}
.general-form input:not([type=radio], [type=checkbox]){background:#FFF;border: 1px solid #D5D5D5;border-radius: 4px;padding: 13px 17px;margin: 0}
.general-form input:not([type=radio], [type=checkbox])::placeholder{color: #D5D5D5}
.general-form input:not([type=radio], [type=checkbox]):focus{box-shadow:none;border-color: #D5D5D5}
.grey-bg-sec{background-image: url(./assets/images/legal-advice-bg.png);background-size: cover;background-repeat: no-repeat;padding: 120px 0 90px}
.grey-bg-sec .title{font-size: 100px;line-height:  1.3;margin-top: -20px}
.grey-bg-sec .content-wrapper{color: #000;}
.grey-bg-sec .content-wrapper h2{color: #002955;}
.grey-bg-sec .ck-content-wrapper ul li::before{background-color: #002955;}
.form-check{padding-left: 2rem;margin: 0}
.form-check .form-check-input[type=radio]{position: relative;background-color: transparent;border-color: var(--theme-primary);width: 19px;height: 19px;margin: .2rem 0 0 -2rem}
.form-check .form-check-input[type=radio]::before{content:"";background-color: #F3F3F3;border-radius: 50%;width: 11px;height: 11px;position: absolute;left: 50%;top: 50%;transform:translate(-50%,-50%)}
.form-check .form-check-input:checked[type=radio]::before{background-color: var(--theme-secondary)}
.form-check .form-check-input:checked{--bs-form-check-bg-image: none}
.form-check .form-check-input:checked + label{color: var(--theme-primary)}
.form-check .form-check-input[type=checkbox]{position: relative;background-color: transparent;width: 18px;height: 18px;border-radius: 50%;border: 2px solid var(--theme-primary);border-top-color: transparent;margin: 0.2rem 0 0 -2rem;transform: rotate(45deg);-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);}
.form-check .form-check-input[type=checkbox]:checked::before{content: "";position: absolute;left: 4px;top: -3px;width: 5px;height: 14px;border: solid var(--theme-primary);border-width: 0 2px 2px 0;}
.admin-multi-sec{padding-top: 80px;}
.admin-multi-sec section:nth-child(even) .row{flex-direction: row-reverse;}
.pagination .page-link{color: var(--text-secondary-color);}
.pagination .active>.page-link{color: #fff;background-color: var(--theme-secondary);border-color: var(--theme-secondary);}
.react-loading-skeleton{background: #ebebeb;filter: blur(1px);border-radius: 8px;}
.no-banner-page{margin-top: 108px;}
.legal-information-page{padding: 85px 0;}
@media (min-width: 1700px) {
  .container { max-width: 1460px; margin: 0 auto; }
}
@media (max-width: 1699px) {
  h1 { font-size: 50px; }
  .sec-right-logo { width: 200px; }
  .grey-bg-sec { padding: 100px 0 80px; }
  .grey-bg-sec .title { font-size: 80px; }
}
@media (max-width: 1399px) {
  body.font-size-xs { --font-size-large: 18px; --font-size-h1: 46px; --font-size-h2: 28px; --font-size-h3: 24px; --font-size-h4: 20px; --font-size-h5: 15px;}
  body.font-size-sm { --font-size-large: 19px; --font-size-h1: 48px; --font-size-h2: 30px; --font-size-h3: 26px; --font-size-h4: 21px; --font-size-h5: 16px;}
  body.font-size-md { --font-size-large: 20px; --font-size-h1: 50px; --font-size-h2: 32px; --font-size-h3: 28px; --font-size-h4: 22px; --font-size-h5: 17px;}
  body.font-size-lg { --font-size-large: 21px; --font-size-h1: 52px; --font-size-h2: 34px; --font-size-h3: 30px; --font-size-h4: 23px; --font-size-h5: 18px;}
  body.font-size-xl { --font-size-large: 22px; --font-size-h1: 54px; --font-size-h2: 36px; --font-size-h3: 32px; --font-size-h4: 24px; --font-size-h5: 19px;}
  .slick-list {margin: 0 -10px;}
  .slick-slide {margin: 0 10px;}
  .general-form input:not([type="radio"], [type="checkbox"]) {padding: 10px 15px;}
  .admin-multi-sec{padding-top: 60px;}
}
@media (max-width: 1199px) {
  .grey-bg-sec {padding: 80px 0 60px;}
  .grey-bg-sec .title {font-size: 65px;}
}
@media (max-width: 991px) {
  body.font-size-xs {--font-size-small: 11px; --font-size-regular: 12px; --font-size-large: 16px; --font-size-h1: 38px; --font-size-h2: 24px; --font-size-h3: 22px; --font-size-h4: 18px; --font-size-h5: 14px; --font-size-h6: 12px;}
  body.font-size-sm {--font-size-small: 12px; --font-size-regular: 13px; --font-size-large: 17px; --font-size-h1: 40px; --font-size-h2: 26px; --font-size-h3: 23px; --font-size-h4: 19px; --font-size-h5: 15px; --font-size-h6: 13px;}
  body.font-size-md {--font-size-small: 13px; --font-size-regular: 14px; --font-size-large: 18px; --font-size-h1: 42px; --font-size-h2: 28px; --font-size-h3: 24px; --font-size-h4: 20px; --font-size-h5: 16px; --font-size-h6: 14px; }
  body.font-size-lg {--font-size-small: 14px; --font-size-regular: 15px; --font-size-large: 19px; --font-size-h1: 44px; --font-size-h2: 30px; --font-size-h3: 25px; --font-size-h4: 21px; --font-size-h5: 17px; --font-size-h6: 15px;}
  body.font-size-xl {--font-size-small: 15px; --font-size-regular: 16px; --font-size-large: 20px; --font-size-h1: 46px; --font-size-h2: 32px; --font-size-h3: 26px; --font-size-h4: 22px; --font-size-h5: 18px; --font-size-h6: 16px;}
  .btn-solid-blue {padding: 14px 20px;}
  .btn-sm {padding: 10px 12px;}
  .slick-slider {padding: 0 20px;}
  .sec-right-logo {width: 160px;}
  .grey-bg-sec .member-img {max-width: 400px;margin: -50px auto 40px;width: 100%;}
  .grey-bg-sec {padding: 0 0 60px;}
  .grey-bg-sec .title {font-size: 50px;margin-top: -10px;}
  .admin-multi-sec{padding-top: 60px;}
  .legal-information-page{padding: 60px 0;}
}
@media (max-width: 767px) {
  /* .form-check .form-check-input[type=checkbox]{width: 20px;height: 20px;margin: 2px 0 0 -1.7rem;} */
}
@media (max-width: 557px) {
  body.font-size-xs {--font-size-large: 14px; --font-size-h1: 32px; --font-size-h2: 24px; --font-size-h3: 20px; --font-size-h4: 17px; --font-size-h5: 13px;}
  body.font-size-sm {--font-size-large: 15px; --font-size-h1: 34px; --font-size-h2: 25px; --font-size-h3: 21px; --font-size-h4: 18px; --font-size-h5: 14px;}
  body.font-size-md {--font-size-large: 16px; --font-size-h1: 36px; --font-size-h2: 26px; --font-size-h3: 22px; --font-size-h4: 19px; --font-size-h5: 15px;}
  body.font-size-lg {--font-size-large: 17px; --font-size-h1: 38px; --font-size-h2: 27px; --font-size-h3: 23px; --font-size-h4: 20px; --font-size-h5: 16px;}
  body.font-size-xl {--font-size-large: 18px; --font-size-h1: 40px; --font-size-h2: 28px; --font-size-h3: 24px; --font-size-h4: 21px; --font-size-h5: 17px;}
  .pre-heading, .details-arrow { font-size: 14px; line-height: 1.3; }
  .slick-slider { padding: 0 10px; }
  .general-form input:not([type="radio"], [type="checkbox"]) { padding: 8px 12px; }
  .grey-bg-sec .title { font-size: 40px; }
  .no-banner-page{margin-top: 83px;}
}