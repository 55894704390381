.video-album {
  padding: 60px 0 100px;
}
.video-album .video-thumbnail {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
.video-album .video-thumbnail video{
  width: 100%;
}
.video-album .video-details p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.video-album .video-details .date {
  width: 52px;
  padding: 6px;
  border-radius: 6px;
  background-color: light-dark(#efe6d9, #edb76a);
}
.video-album .video-details .date .month {
  font-size: 14px;
  line-height: 1.3;
}
.play-icon {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: #ffffffc4;
  padding: 20px;
  border-radius: 50%;
}
.video-modal video {
  width: 100%;
  height: 500px;
}
.video-modal .slick-slider {
  padding: 0;
}
@media (max-width: 991px) {
  .video-album {
    padding: 40px 0 60px;
  }
  .video-modal video {
    height: 400px;
  }
}
@media (max-width: 575px) {
  .video-modal video {
    height: 300px;
  }
}
