.legal-advice {
  background-image: url("../../../assets/images/legal-advice-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #171717;
}
.legal-advice .arbirator-woman {
  top: -100px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.legal-advice .content-wrapper {
  padding: 170px 0 190px;
}
.legal-advice .advice-text {
  font-size: 90px;
  line-height: 1.3;
}
@media (max-width: 1699px) {
  .legal-advice .content-wrapper {
    padding: 130px 0 140px;
  }
  .legal-advice .advice-text {
    font-size: 70px;
  }
}
@media (max-width: 1399px) {
  .legal-advice .content-wrapper {
    padding: 100px 0 110px;
  }
}
@media (max-width: 1199px) {
  .legal-advice .content-wrapper {
    padding: 70px 0 80px;
  }
  .legal-advice .advice-text {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .legal-advice .arbirator-woman {
    position: relative;
    top: -50px;
    max-width: 400px;
    margin: 0 auto;
  }
  .legal-advice .content-wrapper {
    padding: 0 0 60px;
  }
}
@media (max-width: 991px) {
  .legal-advice .advice-text {
    font-size: 40px;
  }
}
