.news-detail-sec {
  padding: 90px 0 0;
}
.news-detail-sec .content-wrapper {
  border-bottom: 1px solid #d5d5d5;
}
@media (max-width: 991px) {
  .news-detail-sec {
    padding: 60px 0 0;
  }
}