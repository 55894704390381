.dasboard-header{
    background-color: light-dark(#fff, #1e2022);
    box-shadow: 0 1px 15px #0000000a,0 1px 6px #0000000a;
    height: 90px;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.dasboard-header .logo-icon{
    filter: invert(1);
}
.main-wrapper.dashboard{
    padding-top: 90px;
}
.dashboard .content-container{width: calc(100% - 250px);margin-left: auto;padding: 40px;}