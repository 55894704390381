.event-sec {
  padding: 50px 0;
  box-shadow: 0px 0px 20px #0000000f;
}
.event-sec .al-left-side-sec {
  z-index: -1;
}
.dark-mode .event-sec .al-left-side-sec img{
  filter: invert(1);
}
.event-sec .the-event {
  position: absolute;
  left: -105px;
  bottom: 50px;
}
.event-sec .popper-lottie {
  width: 90px;
  height: 90px;
}
.event-sec .calendar-lottie {
  width: 160px;
  position: absolute;
  right: -80px;
  top: 50%;
  transform: translate(0, -50%);
}
.event-sec .title {
  font-size: 100px;
  line-height: 1.3;
}
.event-types li {
  padding: 24px 10px;
  border-radius: 6px;
  min-width: 140px;
  background-color: light-dark(#efe6d9, #edb76a);
}
.event-types li:nth-child(2) {
  background-color: light-dark(#e4eed9, #b7e584);
}
.event-types li span {
  font-size: 40px;
  line-height: 1.3;
}
.event-types .event-icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -15px;
  left: -15px;
  border: 1px solid #eee6d9;
}
@media (max-width: 1600px) {
  .event-sec .title {
    font-size: 80px;
  }
  .event-sec .the-event {
    width: 160px;
    left: -80px;
  }
}
@media (max-width: 767px) {
  .event-sec .al-left-side-sec {
    width: 200px;
  }
  .event-sec .title {
    font-size: 65px;
  }
  .event-types .event-icon {
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }
  .event-types li span {
    font-size: 30px;
  }
  .event-sec .the-event {
    width: 120px;
    left: -60px;
  }
  .event-sec .popper-lottie {
    width: 60px;
    height: 60px;
  }
  .event-sec .calendar-lottie {
    width: 120px;
    right: -60px;
  }
}
@media (max-width: 575px) {
  .event-sec .title {
    font-size: 50px;
  }
  .event-types li {
    padding: 15px 10px;
    min-width: 110px;
  }
}
@media (max-width: 375px) {
  .event-sec .al-left-side-sec {
    width: 160px;
  }
  .event-sec .the-event {
    width: 100px;
    left: -50px;
  }
  .event-sec .calendar-lottie {
    width: 100px;
    right: -50px;
  }
}

.event-page-listing .event-card-wrapper {
  background-color: var(--body-secondary-bg);
  border-radius: 8px;
  padding-bottom: 0;
  overflow: hidden;
  transition: transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(255, 255, 255, 0.2);
}

.event-page-listing .event-card-wrapper:hover {
  transform: scale(1.02);
}

.event-page-listing .image-box {
  flex: 0 0 40%;
  height: 238px;
}

.event-page-listing .event-image {
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.event-page-listing .content-box {
  padding: var(--font-size-regular);
  /* flex: 1; */
}
.event-page-listing .event-card-wrapper :is(.location-wrapper, .event-time, .event-dates, .event-status ){
  font-size: var(--font-size-small);
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .event-page-listing .image-box{
    height: 229px;
  }
}
@media (max-width: 991px) {
  .event-page-listing .image-box{
    height: 211px;
  }
}
@media (max-width: 575px) {
  .event-page-listing .image-box{
    height: 160px;
  }
}