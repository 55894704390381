.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.custom-arrow svg {
  width: 22px;
  height: 44px;
  color: var(--theme-secondary);
}
.custom-arrow svg:hover{
  color: var(--info-color);
}
.custom-right-arrow {
  right: -35px;
}
.custom-left-arrow {
  left: -35px;
}
@media (max-width: 991px) {
  .custom-left-arrow {
    left: -10px;
  }
  .custom-right-arrow {
    right: -10px;
  }
}
@media (max-width: 575px) {
  .custom-arrow svg {
    width: var(--font-size-regular);
  }
}
