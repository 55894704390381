.services-card{height: 100%;display: flex;flex-direction: column;}
.services-card .card-content { color: var(--text-secondary-color);background-color: #fff;padding: 25px 22px 22px;box-shadow: 0px 0px 16px #0000001a;border-radius: 8px;width: 100%;max-width: calc(100% - 30px);margin: -95px auto 0;position: relative;z-index: 1;display: flex;flex-direction: column;flex-grow: 1;}
.services-card .img-wrapper { border-radius: 8px;overflow: hidden;flex-shrink: 0;height: 500px;}
.services-card .img-wrapper img {width: 100%;height: 100%;}
.services-card .card-content .pre-heading{display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;}
.services-card .card-content .upper-sec{flex-grow: 1;}
.services-card .card-content h4{display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;height: 63px;}
.services-card .card-content p {display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;}
@media (max-width: 1699px) {
    .services-card .img-wrapper{ height: 450px;}
}
@media (max-width: 1399px) {
    .services-card .card-content h4{height: 58px;}
    .services-card .img-wrapper{ height: 390px;}
}
@media (max-width: 1199px) {
    .services-card .img-wrapper{ height: 320px;}
}
@media (max-width: 991px) {
    .services-card .card-content { padding: 15px;max-width: calc(100% - 20px);margin: -65px auto 0;}
    .services-card .card-content h4{height: 52px;}
}
@media (max-width: 767px) {
    .services-card .img-wrapper{ height: 270px;}
}
@media (max-width: 575px) { 
    .services-card .card-content h4{height: 52px;}
    .services-card .img-wrapper{ height: 320px;}
    .services-card .img-wrapper img { object-fit: cover; }
}
@media (max-width: 399px) {
    .services-card .img-wrapper{ height: 260px;}
}