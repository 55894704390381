.header {position: fixed;z-index: 99;top: 0;left: 0;right: 0;width: 100%;transition: background-color ease-in-out 0.1s;}
.header.add-bg{background-color: #000000;}
.header-black {background: #000;}
.modal-open .header {padding-right: 12px;}
.header .navbar-toggler {padding: 0;border: none;border-radius: 50%;margin-right: 0 !important;}
.header .navbar-toggler-icon {width: 38px;height: 38px;background-color: transparent;background-image: url("../../../assets/images/icon-toggle.svg");background-repeat: no-repeat;background-size: cover;}
.header .navbar-nav .dropdown-menu {min-width: 200px;}
.header .nav-link {color: #fefefe;font-size: 18px;line-height: 24px;padding-right: 12px !important;}
.header .nav-link:hover, .header .nav-link:focus, .header .navbar-nav .nav-link.active, .header .navbar-nav .nav-link.show {color: #ed9c2e;}
.header .nav-item img:hover, .header .toggle-icon img:hover{filter: brightness(0) saturate(100%) invert(67%) sepia(87%) saturate(1180%) hue-rotate(341deg) brightness(99%) contrast(87%);}
.header .nav-item .dropdown-item, .offcanvas .nav-item .dropdown-item {padding: 7px 14px;white-space: normal;}
.header .nav-item .dropdown-item:active, .offcanvas .nav-item .dropdown-item:active{background-color: var(--theme-secondary);}
.header .nav-item .dropdown-item:not(:last-child) {border-bottom: 1px solid rgb(193 193 193 / 26%);}
.header .e-services .dropdown-menu {width: 380px;}
.header .nav-item .en-lang{display: none;}
.rtl .header .nav-item .en-lang{display: block;}
.rtl .header .nav-item .ar-lang{display: none;}
.offcanvas-header .btn-close {width: 35px;height: 35px;background-image: url("../../../assets/images/icon-close.svg");background-size: cover;padding: 0;}
.offcanvas-header .btn-close:focus{box-shadow: none;}
.offcanvas-body .navbar-nav a:not(:last-child),
.offcanvas-body .dropdown-toggle {border-bottom: 1px solid #c1c1c1;}
.offcanvas-body .navbar-nav a:hover {color: #ed9c2e;}
.offcanvas .dropdown-toggle::after {position: absolute;right: 0;}
/* Search Dropdown */
.search-dropdown .search-icon {width: 38px;height: 38px;border: 2px solid #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 8px;}
.search-dropdown .search-icon::after{content: none;}
.search-dropdown .search-icon:hover {border-color: #ed9c2e;color: #ed9c2e;}
.search-dropdown .search-icon:hover img {filter: brightness(0) saturate(100%) invert(67%) sepia(87%) saturate(1180%) hue-rotate(341deg) brightness(99%) contrast(87%);}
.search-dropdown .dropdown-menu{padding: 15px;width: 300px;position: absolute;right: 0;left: auto;margin-top: 10px;}
.search-form .form-control {border-radius: 8px 0 0 8px;border: 1px solid #c1c1c1;border-right: 0;box-shadow: none;}
.search-form .btn-solid-blue{border-radius: 0 8px 8px 0;}
@media (max-width: 1399px) {
  .header .nav-link{font-size: 17px;line-height: 23px;padding-right: 10px !important;}
}
@media(max-width: 1199px){
  .header .nav-link {font-size: 15px;line-height: 22px;padding: 6px !important;}
  .header .nav-item img, .header .toggle-icon, .search-dropdown .search-icon{width: 32px;height: 32px;}
  .header .dropdown-toggle::after{margin-left: 8px;font-size: 10px;}
}
@media (max-width: 991px) {
  .modal-open .header {padding-right: 0;}
  .offcanvas .nav-item .dropdown-item {font-size: 14px;line-height: 1.3;}
  .search-dropdown .dropdown-menu{margin-top: 20px;left: 50%;transform: translateX(-50%);}
}
@media (max-width: 575px) {
  .header .navbar-brand{width: 60px;}
  /* .header .nav-item img, .header .toggle-icon, .search-dropdown .search-icon{width: 32px;height: 32px;} */
  .search-dropdown .dropdown-menu{margin-top: 20px;transform: translateX(-74%);padding: 10px;width: 250px;}
}