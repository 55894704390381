.seminars-conferences {
  padding: 70px 0 80px;
}
.seminars-conferences::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 500px;
  background-image: url("../../../assets/images/seminar-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.seminars-conferences::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 500px;
  background: linear-gradient(180deg, #0625439d 0%, #01070dc6 100%);
}
.seminars-conferences .title {
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  .seminars-conferences {
    padding: 60px 0 30px;
  }
  .seminars-conferences .title {
    margin-bottom: 35px;
  }
}
