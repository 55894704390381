.settings-modal .modal-title{color: var(--theme-primary);}
.settings-modal .font-adjustment-list li{width: 44px;height: 40px;border-radius: 4px;color: #fff;font-size: calc(var(--font-size-large) - 2px);background-color: var(--theme-secondary);}
.settings-modal .grey-box{background-color: var(--body-secondary-bg);border-radius: 10px;padding: 17px 40px 17px 17px;}
.settings-modal .grey-box:not(:last-child){margin-bottom: 6px;}
.settings-modal .themes-list{gap: 25px 0;}
.settings-modal .themes-list .theme-circle{width: 24px;height: 24px;border-radius: 50%;}
.settings-modal .themes-list .theme-circle.golden{background-color: #E2B400;}
.settings-modal .themes-list .theme-circle.blue{background-color: #003e7f;}
.settings-modal .themes-list .theme-circle.green{background-color: #489510;}
.settings-modal .theme-mode-circle, .settings-modal .print-icon-circle{width: 42px;height: 42px;border-radius: 50%;}
.settings-modal .themes-list li .tick-icon-container {display: flex;justify-content: center;align-items: center;border-radius: 50%;box-shadow: 0px 3px 8px #00000029;position: relative;}
.settings-modal .green.tick-icon-container .tick-icon-circle{background-color: #489510;}
.settings-modal .default.tick-icon-container .tick-icon-circle{background-color: #003e7f;}
.settings-modal .golden.tick-icon-container .tick-icon-circle{background-color: #E2B400;}
.settings-modal .tick-icon-circle {width: 24px;height: 24px;border-radius: 50%;background-color: #003e7f;display: flex;justify-content: center;align-items: center;}
.settings-modal .tick-icon {color: white;font-size: 14px;}
.settings-modal .themes-list li{width: 50%;}
.settings-modal .themes-list li input ~ .tick-icon-container::before{content: '';position: absolute;top: -3px;left: -3px;right: -3px;
bottom: -3px;border-radius: 100%;border: 1px dashed transparent;}
.settings-modal .themes-list li input:checked ~ .golden.tick-icon-container::before{border-color: #E2B400;}
.settings-modal .themes-list li input:checked ~ .default.tick-icon-container::before{border-color: #003e7f;}
.settings-modal .themes-list li input:checked ~ .green.tick-icon-container::before{border-color: #489510;}
.settings-modal .themes-list li input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;cursor: pointer;}
.settings-modal .themes-list li input ~ .tick-icon-container .checkmark{display: none;}
.settings-modal .themes-list li input:checked ~ .tick-icon-container .checkmark{display: block;}
.settings-modal .eye-open{display: none;}
.colorless-mode .settings-modal .eye-slash{display: none;}
.colorless-mode .settings-modal .eye-open{display: block;}
.settings-modal :is(.night-mode, .light-mode) {border-radius: 6px;padding: 4px 10px;}
.dark-mode .settings-modal .night-mode, .light-mode .settings-modal .light-mode{background-color: var(--theme-secondary);color: #fff;}
@media(max-width: 480px){
    .settings-modal .modal-content{padding-inline: 15px;}
    .settings-modal .grey-box { padding: 15px 10px 15px 10px;}
}