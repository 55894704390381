.procedure-followed {
  padding: 120px 0;
}
.procedure-followed .al-logo {
  left: 0;
  top: -30px;
}
@media (max-width: 1399px) {
  .procedure-followed {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .procedure-followed {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .procedure-followed .meeting {
    width: calc(100% - 40px);
    margin-left: auto;
  }
}
